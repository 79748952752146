import './App.css';
import React from 'react';
import Form from "@rjsf/core";
import _ from "lodash";
import axios from 'axios';
import LayoutGridField from 'react-jsonschema-form-layout-grid' // https://github.com/Narazaka/react-jsonschema-form-layout-grid
import {
  ArrayFieldTemplate, 
  ReactSelectWidget, 
  ReactSignatureCanvasField,
  ReactInputTableWidget,
  // SegmentedWidget
} from 'react-jsonschema-form-ui';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast } from 'bootstrap';
import {default_jha_tempalte} from "./form_templates";


const widgets = {
  ReactSelectWidget: ReactSelectWidget,
  // SegmentedWidget: SegmentedWidget
};

const fields = {
  layout_grid: LayoutGridField,
  ReactSignatureCanvasField: ReactSignatureCanvasField,
  ReactInputTableWidget: ReactInputTableWidget
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toast: null
    };
  }

  componentDidMount(){
    this.toast = new Toast(document.getElementById("sentToast"), {autohide: true, delay: 3000});
  }

  render(){
    return (
      <div className="App">

        <div id="hero" className="pb-5">
          
            <div className="container">

              <h2 style={{color: "white", fontWeight: "bold"}}>The easiest way to complete your daily Job Hazard Analysis(JHA).</h2>
            
              <div className="row">
                <a className="btn btn-light btn-lg col-sm-12 mx-2 mb-2" href="#start">Start New JHA</a>
                <a className="btn btn-dark btn-lg col-sm-12 col-md-3 mx-2 mb-2" style={{minWidth: "20%"}} href="https://marketplace.procore.com/apps/job-site-forms-by-responsevault">Integrate with Procore</a>
                <a className="btn btn-dark btn-lg col-sm-12 col-md-3 mx-2 mb-2" href="https://app.responsevault.com/users/sign_in">Integrate with Plangrid</a>
              </div>
             
            </div>

        </div>
  
      <br></br>
  
        <div className="mt-3 mb-5 px-2">


          <div className="container">

            <h2>What is a Job Hazard Analysis(JHA)?</h2>
            <p>Many workers are injured each year and a significant portion of those injuries occur because proper Personal Protective Equipment(PPE) is not worn, and/or proper hazard control procedures are not undertaken.</p>
            <p>A Job Hazard Analysis(JHA) is a tool that plays a proactive role in fostering a culture of safety. The goal is to provide sanity checks to your team that encourage them to identify potential hazards and their effects at the outset of the job, and proactively list the step to mitigate an incident.</p>
            <p>In the event of an incident, supervisors can use the JHA to understand the thoughts of the team before the job steps were started, and identify parts of the process that need to be improved to prevent a similar incident.</p>
            
            <br />
            <p>Read: <a href="/resources/6-benefits-of-job-hazard-analysis.html">The 6 Benefits of Job Hazard Analysis</a></p>
            
            {/* <h3>Premium Features</h3>

            <h4>Photos</h4>
            <p></p>
            
            <h4>Integrations with Project Management Apps</h4>
            <p>Including Procore and Plan Grid.</p>

            <h4>Customizable Hazards, Controls, Likelihood &amp; Consequence Riskt Matrix</h4> */}
          </div>

          <br /><hr /><br />

          <div className="safety-report-container" id="start">
  
            <div className="row">
              <h2 className="col-sm-12 col-md-8 mb-3">Job Hazard Analysis (JHA)</h2>
              <div className="col-sm-12 col-md-4 mb-3 btn-group">
                <a className="btn btn-primary" href="/my_project_site_jha_2022-04-14.pdf" target="_blank">View Sample JHA PDF</a>
              </div>
            </div>
            
            
            <Form
              schema={default_jha_tempalte.schema}
              uiSchema={default_jha_tempalte.uiSchema}
              formData={this.state.formData}
              ArrayFieldTemplate={ArrayFieldTemplate}
              fields={fields}
              widgets={widgets}
              onChange={(form) => {
                console.log(form);
  
              }}
              onSubmit={(form) => {
                this.setState({isProcessing: true});
                axios({
                  url: "https://free-safety-report.responsevault.autocode.gg/handleJHASubmission/",
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  data: form
                }).then((result)=>{
                  this.setState({formData: form.formData, isProcessing: false, toast: {title: "Report Sent!", body: "Your JHA has been sent!"}});
                  this.toast.show();
                })
  
              }}
            >
              <button type="submit" className="btn btn-primary" disabled={this.state.isProcessing}>
                {this.state.isProcessing &&
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                }
                Email JHA as PDF
              </button>
            </Form>
  
  
          </div>
        </div>
  
        <style>
          {default_jha_tempalte.css}
        </style>

        <div className="position-fixed m-2" style={{top: 20, right: 20, zIndex: 120}}>
          <div className="toast-container">
            <div className="toast fade" id="sentToast" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <strong className="me-auto">{_.get(this.state.toast, 'title')}</strong>
              </div>
              <div className="toast-body">
                {_.get(this.state.toast, 'body')}
              </div>
            </div>
          </div>
        </div>

        <hr /><br /><br />

        <p style={{textAlign: "center"}}>&copy; 2022 ResponseVault</p>

        <br /><br/>
      </div>
    );
  }
}

export default App;
