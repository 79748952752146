module.exports = {
    "schema": {
        "type": "object",
        "required": [
            "date",
            "submitted_by",
            "project",
            "email_pdf_to"
        ],
        "properties": {
            "date": {
                "type": "string",
                "title": "Date",
                "format": "date"
            },
            "notes": {
                "type": "string",
                "title": "Notes",
                "default": "",
                "description": ""
            },
            "steps": {
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                        "description": {
                            "type": "string",
                            "title": "Description of Step",
                            "default": "",
                            "description": ""
                        },
                        "hazards": {
                            "type": "array",
                            "uniqueItems": true,
                            "items": {
                                "enum": [
                                    "Congested Area",
                                    "Fire",
                                    "Overhead Work",
                                    "Combustibles",
                                    "Spills",
                                    "Wet Conditions",
                                    "Heavy Lifting",
                                    "Line of Fire",
                                    "Hex Chrome",
                                    "Lead",
                                    "Asbestos",
                                    "H₂S",
                                    "SO₂",
                                    "Fall Exposure",
                                    "Chem. Burn",
                                    "Thermal Burn",
                                    "Head Knockers",
                                    "Slippery Surface",
                                    "Poor Lighting",
                                    "Pinch Points",
                                    "Holes/ Openings",
                                    "Airborne Particulate",
                                    "Excavation Stability",
                                    "Hazardous Atmosphere",
                                    "Water Accumulation",
                                    "Adverse Weather",
                                    "Overhead Lifts",
                                    "Sharp Edges",
                                    "Electrocution",
                                    "Nitrogen",
                                    "Slips/Trips",
                                    "Damaged Tools",
                                    "High Noise",
                                    "Stored Energy"
                                ],
                                "type": "string",
                            },
                            "title": "Potential Hazards"
                        },
                        "risk_control_measures": {
                            "type": "string",
                            "title": "Risk Control Measures"
                        },
                        "consequence": {
                            "type": "number",
                            "title": "Consequence",
                            "enum": [1,2,3,4,5],
                            "enumNames": [
                                "Negligible (1)",
                                "Minor (2)",
                                "Moderate(3)",
                                "Significant (4)",
                                "Severe (5)"
                            ]
                        },
                        "likelihood":{
                            "type": "number",
                            "title": "Likelihood",
                            "enum": [1,2,3,4,5],
                            "enumNames": [
                                "Very Unlikely (1)",
                                "Unlikely (2)",
                                "Possible (3)",
                                "Likely (4)",
                                "Very Likely (5)"
                            ]
                        },
                        "result": {
                            "type": "string",
                            "title": "Risk Level (Result)",
                            "enum": [
                                "Negligible",
                                "Low",
                                "Intermediate",
                                "High",
                                "Extreme"
                            ]
                        }
                    }
                    
                },
                "title": "Job Steps",
                "default": [],
                "minItems": 1
            },
            "risk_matrix": {
                "type": "object",
                "readOnly": true,
                "title": "Risk Matrix: Likelihood x Consequences",
                "properties": {
                    "row": {
                        "type": "string"
                    },
                    "column": {
                        "type": "string"
                    }
                }
            },
            "project": {
                "type": "string",
                "title": "Project",
                "default": "",
                "description": ""
            },
            "location": {
                "type": "string",
                "title": "Location",
                "default": "",
                "description": ""
            },
            "email_pdf_to": {
                "type": "array",
                "items": {
                    "type": "object",
                    "required": [
                        "email"
                    ],
                    "properties": {
                        "email": {
                            "type": "string",
                            "title": "Email",
                            "width": 12,
                            "default": "",
                            "description": ""
                        }
                    }
                },
                "title": "Email PDF To",
                "default": [],
                "minItems": 1
            },
            "submitted_by": {
                "type": "object",
                "title": "Submitted By",
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Name"
                    },
                    "email": {
                        "type": "string",
                        "title": "Email"
                    },
                    "phone": {
                        "type": "string",
                        "title": "Phone Number",
                        "format": ""
                    },
                    "signature": {
                        "type": "string"
                    }
                }
            },
            "ppe": {
                "type": "array",
                "title": "PPE(Personal Protective Equipment) Required",
                "items": {
                    "type": "object",
                    "properties": {
                        "name":{
                            "type":"string",
                            "title": "Name"
                        },
                        "in_use": {
                            "type": "boolean",
                            "title": "In Use",
                            "default": false
                        }
                    }
                },
                "default": [
                    {
                        "name": "Glasses",
                        "in_use": false
                    },
                    {
                        "name": "Hi-Viz",
                        "in_use": false
                    },
                    {
                        "name": "Hard Hat",
                        "in_use": false
                    },
                    {
                        "name": "Fire Extinguisher",
                        "in_use": false
                    },
                    {
                        "name": "Fire Watch",
                        "in_use": false
                    },
                    {
                        "name": "Ear Protection",
                        "in_use": false
                    }
                ]
            }
        }
    },
    "uiSchema": {
        "date": {
            "layout": {
                "w": 12,
                "h": 1,
                "x": 0,
                "y": 0,
                "i": "date",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "date",
            "ui:options": {
                "inputType": "date"
            }
        },
        "notes": {
            "layout": {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 5,
                "i": "notes",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "multilinetext",
            "ui:widget": "textarea",
            "ui:options": {
                "rows": 5
            }
        },
        "steps": {
            "items": {

                "hazards": {
                    "fieldType": "react-select",
                    "ui:widget": "ReactSelectWidget",
                    "ui:options": {
                        "isCreateable": false,
                        "isList": true,
                        "isClearable": false

                    }
                },
                "risk_control_measures": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        "rows": 2
                    }
                },
                "ui:field": "layout_grid",
                "description": {
                    "layout": {
                        "w": 12
                    },
                    "fieldType": "multilinetext",
                    "ui:widget": "textarea",
                    "ui:options": {
                        "rows": 2
                    }
                },
                "ui:layout_grid": {
                    "ui:row": [
                        
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "description"
                                ]
                            }
                        },
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "hazards"
                                ]
                            }
                        },
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "risk_control_measures"
                                ]
                            }
                        },                        
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "consequence"
                                ]
                            }
                        },                        
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "likelihood"
                                ]
                            }
                        },                      
                        {
                            "ui:col": {
                                "md": 2,
                                "children": [
                                    "result"
                                ]
                            }
                        }
                    ]
                }
            },
            "fieldType": "repeating_section"
        },
        "risk_matrix": {
            "classNames": "cover",
            "ui:field": "ReactInputTableWidget",
            "ui:options": {
                "rows": [
                    "Very Likely (5)",
                    "Likely (4)",
                    "Possible (3)",
                    "Unlikely (2)",
                    "Very Unlikely (1)"
                ],
                "values": [
                    [
                        5,
                        10,
                        20,
                        20,
                        25
                    ],
                    [
                        4,
                        8,
                        12,
                        16,
                        20
                    ],
                    [
                        3,
                        6,
                        9,
                        12,
                        15
                    ],
                    [
                        2,
                        4,
                        6,
                        8,
                        10
                    ],
                    [
                        1,
                        2,
                        3,
                        4,
                        5
                    ]
                ],
                "columns": [
                    "Negligible (1)",
                    "Minor (2)",
                    "Moderate(3)",
                    "Significant (4)",
                    "Severe (5)"
                ],
                "inputTableType": "button"
            }
        },
        "project": {
            "layout": {
                "w": 12,
                "h": 1,
                "x": 0,
                "y": 1,
                "i": "project",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "string",
            "ui:options": {
                "inputType": "text"
            }
        },
        "location": {
            "layout": {
                "w": 12,
                "h": 1,
                "x": 0,
                "y": 2,
                "i": "location",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "string",
            "ui:options": {
                "inputType": "text"
            }
        },
        "ui:field": "layout_grid",
        "ui:options": {
            "cols": {
                "lg": 36,
                "md": 24,
                "sm": 6,
                "xs": 2,
                "xxs": 1
            },
            "breakpoints": {
                "lg": 1200,
                "md": 996,
                "sm": 768,
                "xs": 480,
                "xxs": 0
            }
        },
        "email_pdf_to": {
            "items": {
                "email": {
                    "fieldType": "string",
                    "ui:options": {
                        "inputType": "email"
                    }
                },
                "ui:field": "layout_grid",
                "ui:layout_grid": {
                    "ui:row": [
                        {
                            "ui:col": {
                                "md": 12,
                                "children": [
                                    "email"
                                ]
                            }
                        }
                    ]
                }
            },
            "layout": {
                "w": 12,
                "h": 1,
                "x": 0,
                "y": 13,
                "i": "email_pdf_to",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "repeating_section"
        },
        "submitted_by": {
            "email": {
                "ui:options": {
                    "inputType": "email"
                }
            },
            "phone": {
                "ui:options": {
                    "inputType": "tel"
                }
            },
            "layout": {
                "w": 12,
                "h": 6,
                "x": 0,
                "y": 7,
                "i": "submitted_by",
                "minW": 1,
                "minH": 1,
                "moved": false,
                "static": false
            },
            "fieldType": "object",
            "signature": {
                "ui:field": "ReactSignatureCanvasField"
            }
        },
        "ui:layout_grid": {
            "ui:row": [
                {
                    "ui:col": {
                        "md": 12,
                        "children": [
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "date"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "project"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "location"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "risk_matrix"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "steps"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "ppe"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "notes"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "submitted_by"
                                            ]
                                        }
                                    }
                                ]
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": []
                            },
                            {
                                "ui:row": [
                                    {
                                        "ui:col": {
                                            "md": 12,
                                            "children": [
                                                "email_pdf_to"
                                            ]
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ppe": {
            "items": {
                "ui:field": "layout_grid",
                "ui:layout_grid": {
                    "ui:row": [
                        {
                            "ui:col": {
                                "md": 7,
                                "children": ["name"]
                            }
                        },
                        {
                            "ui:col": {
                                "md": 4,
                                "className": "offset-md-1",
                                "children": ["in_use"]
                            }
                        }
                    ]
                },
                "in_use": {
                   "ui:widget": "radio"
                }
            }
        }
    },
    "css": `
    #root_steps__title, #root_ppe__title, #root_email_pdf_to__title{
        top:0;
        position: sticky;
        z-index: 100;
        padding: 5px;
        background-color: white;
        border-bottom: #aaa 1px solid;
    }`
}